







































import { Vue, Component, Prop } from 'vue-property-decorator'
import Logo from "../../@core/layouts/components/Logo.vue";
import {BButton, BImg, BLink} from "bootstrap-vue";
import store from "../../store";

@Component({
  components: {
    Logo,
    BLink,
    BButton,
    BImg,
  }
})
export default class Error404 extends Vue {
  downImg = require('@/assets/images/pages/error.svg')

  get imgUrl() {
    // @ts-ignore
    if (store.state.appConfig.layout.skin === 'dark') {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.downImg = require('@/assets/images/pages/error-dark.svg')
      return this.downImg
    }
    return this.downImg
  }
}
